var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"23eFgz1LJ70j9j9vAGfEM"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Environment } from './environment';

const SENTRY_DSN = Environment.sentryDsn;

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 0.01,
  environment: process.env.NODE_ENV,
});
