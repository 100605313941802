import React from 'react';
import { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { UsersApi } from '@/services/users/users_api';
import { stateToken, stateUser } from '@/states/auth';
import { StateManagerContext } from './context';
import { cleanUrlParam } from 'routes';
import { isLoggedIn } from 'utils/auth';

/**
 * STATES LIST
 */
export const statesList = [stateToken, stateUser];

/**
 * PROPS
 *
 * @param {React.ReactElement} children - children component
 */
interface IPropsStateManager {
  children: React.ReactElement | React.ReactElement[];
}

/**
 * STATE MANAGER
 */
export default function StateManager(Props: IPropsStateManager): React.ReactElement {
  const [user, setUser] = useRecoilState(stateUser);
  const [token] = useRecoilState(stateToken);
  const { children } = Props;

  //
  // CLEAR FUNCTIONS
  //
  const clearToken = useResetRecoilState(stateToken);
  const clearUser = useResetRecoilState(stateUser);
  const clearAllStates = useCallback(() => {
    clearToken();
    clearUser();
  }, [clearToken, clearUser]);
  const clearStateFunctions = {
    clearAllStates,
    clearToken,
    clearUser,
  };

  //
  // UPDATE METHODS
  //
  // update user data
  const updateUserData = useCallback(() => {
    const callUserDetails = async () => {
      const usersApi = new UsersApi(token!);
      const res = await usersApi.get.getRetrieve(cleanUrlParam(user!.id));
      if (!res.error && !!res.obj && !(res.obj instanceof Array)) {
        setUser(res.obj);
      }
    };

    if (isLoggedIn(user) && !!token?.length) {
      callUserDetails();
    }
  }, [user, token, setUser]);

  const updateStateFunctions = {
    updateUserData,
  };

  /**
   * RENDER
   */
  return (
    <StateManagerContext.Provider value={{ updateStateFunctions, clearStateFunctions }}>
      {children}
    </StateManagerContext.Provider>
  );
}
