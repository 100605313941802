import getConfig from 'next/config';

const getEnvironmentVariable = (environmentVariable: string): string => {
  const val = getConfig().publicRuntimeConfig[environmentVariable];
  if (typeof val != 'undefined') {
    return val;
  } else {
    throw new Error(`Environment variable: ${environmentVariable} is required.`);
  }
};

/*
 * Environment
 *
 * Store environment variables for access throughout
 * the application. Be sure to declare any and all
 * environment variables you want here as well
 * as in 'next.config.js'
 */
export const Environment = {
  uriSite: getEnvironmentVariable('URI_SITE'),
  uriApi: getEnvironmentVariable('URI_API'),
  sentryDsn: getEnvironmentVariable('SENTRY_DSN'),
};

export const isProduction = process.env.NODE_ENV === 'production';
export const isDevelopment = process.env.NODE_ENV === 'development';
