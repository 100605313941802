import { Environment } from 'environment';

/**
 * ROUTES
 *
 * Declare your routes in this class. Not required to use but helpful for organization
 * Add a method for your route, with or without parameters, then call it like:
 *
 *   await router.push(Routes.routeHome())
 *
 * Trailing '/' are preferred :)
 */

export default class Routes {
  static routeHome() {
    return '/';
  }

  static routeAdmin() {
    // NOTE: this is an absolute URL because it's an 'external' link to the backend
    return `${Environment.uriApi}/management/`;
  }

  static routeLogin() {
    return '/login/';
  }

  static routeSignup() {
    return '/signup/';
  }

  static routePasswordResetRequest() {
    return '/password-reset/request/';
  }

  static routePasswordResetChange(userToken: string, userId: string) {
    return `/password-reset/change/${userToken}/${userId}/`;
  }

  static routeContact() {
    return 'https://www.danielnazarian.com/contact/';
  }

  //
  // BLOG
  //
  static routeBlogResults() {
    return '/blog/posts/';
  }

  static routeBlogDetails(postId: string) {
    return `/blog/posts/${postId}/`;
  }
}

export const cleanUrlParam = (input: string | string[] | undefined | null): string => {
  /**
   * Clean up a URL parameters given from NextJS
   * NextJS gives URL params as a `string[] | string | undefined`
   * use this to easily convert to a string
   *
   * @param input - the URL parameter to clean up
   */
  if (typeof input === 'undefined' || input === null) {
    return '';
  }
  return Array.isArray(input) ? input[0] : input;
};
