import { IUser } from '@/models/IUser';

/**
 * Check if user is logged in.
 *
 * @param {IUser} user - The user to check.
 */
export const isLoggedIn = (user: IUser | null) => {
  if (!user || user === null) {
    return false;
  }
  return !(typeof user.id === 'undefined' || user === null || user.id == '');
};
