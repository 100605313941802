import '@/styles/globals.scss';
import { ThemeProvider } from 'next-themes';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { RecoilRoot } from 'recoil';
import StateManager from '@/components/state_manager';
import type { AppProps } from 'next/app';

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <RecoilRoot>
      <ThemeProvider>
        <StateManager>
          <Toaster />
          <Component {...pageProps} />
        </StateManager>
      </ThemeProvider>
    </RecoilRoot>
  );
}
